import Router from "vue-router";
import { getAuth } from "firebase/auth";

const routes = [
  {
    path: "/",
    alias: "/home",
    name: "home",
    component: () => import("./components/pages/Home")
  },
  {
    path: "/order/*",
    name: "order_status",
    component: () => import("./components/pages/OrderStatus")
  },
  {
    path: "/viewer",
    name: "viewer",
    component: () => import("./components/elements/CesiumViewer")
  },
  {
    path: "*",
    component: { template: "<div>Page not found</div>" }
  }
];

const router = new Router({
  mode: 'history',
  routes: routes
});

router.beforeEach((to, from, next) => {
  const init = require('../runacc.js');
  init.initialize();

  var path = to.path
  if (to.matched.some(record => record.meta.authRequired)) {
    if (getAuth().currentUser) {
      next();
    } else {
      alert('You must be logged to see this page');
      next({
        path: '/',
      });
    }
  }
  else if(path.includes("/order/")){
    var paramsOrder = to.params.pathMatch
    console.log(paramsOrder)
    next()
  }
  else {
    next();
  }
});

export default router
