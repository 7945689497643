//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {getAuth} from "firebase/auth";
import EventBus from "../../../event-bus";

export default {
  name: "Header",
  mounted() {
    EventBus.$on("login", () => {
      this.methodInChild();
    });
  },
  methods: {
    logout: function () {
      this.$router.replace('/')
      this.user = false
      getAuth().signOut()
      //EventBus.$emit("logout");
    },
    methodInChild() {
      this.user = true
    },
  },
  data() {
    return {
      user: false,
    }
  },
  created() {
    this.user = !!getAuth().currentUser;
  },

}
