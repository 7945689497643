import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VueSession from "vue-session";

import router from './router'
import '@/assets/css/global.css'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueSocketIO from 'vue-socket.io';
import socketio from 'socket.io-client';

const SocketInstance = socketio.connect('https://serverlive.aerialoop.com', {
  query: {
    token: window.localStorage.getItem('auth')
  }
});

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketInstance
}));

Vue.config.productionTip = false

Vue.use(VueApexCharts)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSession);

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAneUifnjITlPaiDWn3DF3DuBNfgeya1Ng",
  authDomain: "aerialoop-fleet-management.firebaseapp.com",
  databaseURL: "https://aerialoop-fleet-management-default-rtdb.firebaseio.com",
  projectId: "aerialoop-fleet-management",
  storageBucket: "aerialoop-fleet-management.appspot.com",
  messagingSenderId: "1091895789021",
  appId: "1:1091895789021:web:2c2080074b5759d505a234",
  measurementId: "G-4RXSK3FGKT"
};

initializeApp(firebaseConfig);


new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
