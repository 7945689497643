//
//
//
//
//
//
//

import Header from "./components/elements/Header.vue";
import Footer from "./components/elements/Footer.vue";

export default {
  name: "app",
  components: {
    Header,
    Footer,
  },
};
